<template>  
  <div :style="style" class="loader d-flex flex-column align-items-center justify-content-center">                          
    <canvas id='canvas'></canvas>                    
    <div class="d-flex flex-column position-fixed align-items-center">                          
      <img class="holly" src="../assets/holly.png"/>
    </div>
    <div class="d-flex flex-column position-fixed align-items-center">                          
      <img class="mt-4 elves" src="../assets/elves.png"/>    
      <div class="d-flex justify-content-center align-items-center spinnerAndText">                
        <template v-if="spinVisible">          
          <font-awesome-icon class="spinner" spin :icon="['fad', 'spinner-third']"/>                    
        </template>        
        <div class="msg ml-4">{{message}}</div>    
      </div>        
    </div>          
    <div class="bottom-bar d-flex ml-3 mb-4">                     
      <span class="ml-auto mr-3">{{appVersion}}</span>      
    </div>            

    <div class="d-flex flex-column position-fixed align-items-center icon">              
      <img class="mb-icon" v-if="$appGlobals.environment === 'production'" src="../assets/icon200x200.png"/>
        <img class="mb-icon" v-if="$appGlobals.environment === 'development'" src="../assets/icon200x200_dev.png"/>
        <img class="mb-icon" v-if="$appGlobals.environment === 'staging'" src="../assets/icon200x200_stage.png"/>
    </div>      
  </div>      
</template>

<script>
export default {
  props: {
    visible: { type: Boolean },        
    appVersion: { type: String, required: true},    
    spinVisible: { type: Boolean, default: true },
    message: { type: String, default: "..." },    
  },
  data() {
    return {
      doneWith: false,
    }
  },
  computed: {    
    style() {
      return {
        opacity: this.visible ? 1 : 0,
        visibility: this.visible ? "visible" :'hidden',
        pointerEvents: 'none'
      }      
    }
  },     
  mounted() {

    window.addEventListener('resize', function(){
      c.width = w = window.innerWidth;
      c.height = h = window.innerHeight;
    }, false);

    var c = document.getElementById('canvas'), 
    $ = c.getContext("2d");
    var w = c.width = window.innerWidth, 
    h = c.height = window.innerHeight;

    Snowy();

    function Snowy() {
      var snow, arr = [];
      var num = 600, tsc = 1, sp = 1;
      var sc = 1.3, t = 0, mv = 20, min = 1;

      for (var i = 0; i < num; ++i) {
        snow = new Flake();
        snow.y = Math.random() * (h + 50);
        snow.x = Math.random() * w;
        snow.t = Math.random() * (Math.PI * 2);
        snow.sz = (100 / (10 + (Math.random() * 100))) * sc;
        snow.sp = (Math.pow(snow.sz * .8, 2) * .15) * sp;
        snow.sp = snow.sp < min ? min : snow.sp;
        arr.push(snow);
      }

      go();

      function go()
      {
        window.requestAnimationFrame(go);
        $.clearRect(0, 0, w, h);
        $.fillStyle = 'hsla(220, 13.04%, 18.04%, 1)';
        $.fillRect(0, 0, w, h);
        $.fill();
          for (var i = 0; i < arr.length; ++i) {
            var f = arr[i];
            f.t += .05;
            f.t = f.t >= Math.PI * 2 ? 0 : f.t;
            f.y += f.sp;
            f.x += Math.sin(f.t * tsc) * (f.sz * .3);
            if (f.y > h + 50) f.y = -10 - Math.random() * mv;
            if (f.x > w + mv) f.x = - mv;
            if (f.x < - mv) f.x = w + mv;
            f.draw();}
      }

      function Flake() {

        this.draw = function() 
        {
            this.g = $.createRadialGradient(this.x, this.y, 0, this.x, this.y, this.sz);
            this.g.addColorStop(0, 'hsla(255,255%,255%,1)');
            this.g.addColorStop(1, 'hsla(255,255%,255%,0)');
            $.moveTo(this.x, this.y);
            $.fillStyle = this.g;
            $.beginPath();
            $.arc(this.x, this.y, this.sz, 0, Math.PI * 2, true);
            $.fill();
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import "../variables";

.loader {
  z-index:998;
  position: fixed;
  background: $panelContent;
  color:$textColourHover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity:1;
  transition:visibility 5.0s linear,opacity 1s linear;

  #canvas {
    height: 100%;
    position: fixed;    
  }

  .title {
    color:$textColour;    
    font-size: 2.0rem;  
    font-weight: 100;
  }
  /*padding: 20px;*/

  .holly {
    height: 2200px;
    width: 2200px;
    opacity: 0.8
  }

  .pointer-events-none {
     pointer-events: none;
  }

  .icon {
    bottom: 70px;
    right: 15px;
  }

  .mb-icon {
    width: 128px;
    height: 128px;
  }

  .elves {
    width: 500px;
    height: 500px;
    border-color:bisque;
    border-style: solid;
    border-radius: 10px 10px 10px 10px;
    border-width: 2px;
  }

  .spinnerAndText {
    height: 90px;
  }
  
  .spinner {
    
    color:$textColour;    
    font-size: 4.0rem;      
  }

  .msg {
    font-size: 1.4em;
    margin-left: 8px;
    
  }

  .bottom-bar {
    position: fixed;        
    bottom: 0;        
    color:$textColourHover; 
    font-size: 1.2em;      
    width: 100%;
    margin-right: 90px;
  }
}

</style>
